<template>
	<vvo v-slot="v">
		<div>
			<header-box :title="`${$t('코드 관리')} > ${$t('공통 코드')} > ${$t('소분류 코드')}`" />
			<div class="col-xl-12">
				<hr class="mt-3 mb-3" />

				<div class="row">
					<search-box :ref="'search'" @search:data="onSearch" @insertRow="insertRow" @save="onValidate(v, save, selectedRow)"
						@remove="remove" />
				</div>

				<div class="bg-white">
					<grid-box ref="codeDetail" :data="items" @reSearch="reSearch" @getSelectedRow="getSelectedRow" />
				</div>
			</div>
		</div>
	</vvo>
</template>

<script>
import SearchBox from "./SmallSearchBox.vue";
import GridBox from "./PublicSmallCodeTable.vue";
import HeaderBox from "@/views/component/headerBox/sys/Header.vue";
import backEndApi from "@src/api/backEndApi";
import { StatusCodes } from "http-status-codes";
import AppErrorHandler from "@src/appErrorHandler";
import * as popupMessages from "@src/consts/popupMessageConsts";

export default {
	name: "PointMgmt",
	props: [],
	components: {
		SearchBox,
		GridBox,
		HeaderBox,
	},
	watch: {},
	data() {
		return {
			items: null,
			selectedRow: [],
			searchLargeCode: "",
			notRemovingNewRow: [],
		};
	},
	created() {
		console.log("point management pointMgmt");
	},
	mounted() { },
	methods: {
		getSelectedRow(data) {
			this.selectedRow = data;
		},
		onSearch({ result, largeCode }) {
			if (this.items !== null) {
				for (let i = 0; i < result.length; i++) {
					let existItem = this.items.find(v => v.smallCode === result[i].smallCode);
					if (existItem) {
						result[i].checked = existItem.checked;
					} else {
						result[i].checked = false;
					}
				}
			} else {
				result = result.map(v => {
					v.checked = false;
					return v;
				})
			}

			this.items = result;
			this.items = [...this.items, ...this.notRemovingNewRow];
			this.searchLargeCode = largeCode;
			this.notRemovingNewRow = [];
		},
		insertRow() {
			let newIndex = Math.max(
				...this.items.map((o) => {
					if (o.newIndex) return o.newIndex;
					else return 0;
				})
			);

			if (isNaN(newIndex)) newIndex = this.items.length;

			newIndex = newIndex + 1;

			this.items.push({
				isNew: true,
				newIndex: newIndex,
				checked: true,
				smallCode: "",
				smallCodeName: "",
				enabled: "",
				sortOrder: "",
				etcInfo1: "",
				etcInfo2: "",
				etcInfo3: "",
				etcInfo4: "",
				etcInfo5: "",
				rmk: "",
			});
		},

		async save() {
			if (this.selectedRow.length === 0) {
				this.alertNoti(popupMessages.COMMON_SAVE_NO_DATA_POPUP_MESSAGE);
				return;
			}
			// validation check
			for (let i = 0; i < this.selectedRow.length; i++) {
				const data = this.selectedRow[i];

				// if (data.smallCode === "") {
				// 	this.alertNoti(popupMessages.CODE_SMALL_CODE_REQUIRED_VALID_POPUP_MESSAGE);
				// 	return;
				// }
				// if (data.smallCodeName === "") {
				// 	this.alertNoti(popupMessages.CODE_SMALL_CODE_NAME_REQUIRED_VALID_POPUP_MESSAGE);
				// 	return;
				// }
				if (data.enabled === "") {
					this.alertWarning(popupMessages.COMMON_USAGE_VALID_POPUP_MESSAGE);
					return;
				}
			}

			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].isNew === true && this.selectedRow.findIndex((v) => v.newIndex === this.items[i].newIndex) === -1) {
					this.notRemovingNewRow.push(this.items[i]);
				}
			}

			let largeCode = this.searchLargeCode;

			this.selectedRow.forEach((data) => {
				if (data.isNew) delete data.isNew;
				if (data.newIndex) delete data.newIndex;
				if (data.largeCode) delete data.largeCode;
				if (data.checked) delete data.checked;

			});

			// console.log(sendData);
			let result;
			let saveCount = 0;
			let sendData = {
				largeCode: largeCode,
				smallCodeList: this.selectedRow,
			};

			// 이전 리비전과 소스 에서 아래와 같이 변경함.
			/*           
	↓↓↓↓↓
  */
			try {
				result = await backEndApi.publicCode.saveSmallCode(sendData);
			} catch (err) {
				new AppErrorHandler(err)
					.setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger)
					.createApiHandle(StatusCodes.BAD_REQUEST)
					.createBizHandle(this.ERR_KIND.MISSING_ARGUMENT, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
					.createBizHandle(this.ERR_KIND.INVALID_ARGUMENT, popupMessages.COMMON_SAVE_ERROR_POPUP_MESSAGE, this.alertDanger)
					.printErrorLog()
					.errHandling();
				return;
			}

			const countString = result.data.message.split("건이");
			saveCount += Number(countString[0]);
			this.resetValue();

			this.alertNoti(saveCount + popupMessages.CODE_REGIST_POPUP_MESSAGE());
			this.reSearch();
		},
		async remove() {
			if (this.selectedRow.length <= 0) {
				this.alertNoti(popupMessages.COMMON_REMOVE_NO_DATA_POPUP_MESSAGE);
				return;
			}

			let removeCount = 0;

			for (let i = 0; i < this.selectedRow.length; i++) {
				let removeCode = this.selectedRow[i];

				if (removeCode.isNew === true) {
					let itemIndex = this.items.findIndex((v) => v.newIndex === removeCode.newIndex);
					this.items.splice(itemIndex, 1);
					this.selectedRow.splice(i, 1);
					removeCount++;
					i--;
				}
			}
			for (let i = 0; i < this.items.length; i++) {
				if (this.items[i].isNew === true) {
					this.notRemovingNewRow.push(this.items[i]);
				}
			}

			const data = this.selectedRow.map((data) => data.smallCode);

			if (data.length > 0) {
				const params = { largeCode: this.searchLargeCode, smallCode: data };

				// 이전 리비전과 소스 에서 아래와 같이 변경함.
				/*           
	  ↓↓↓↓↓
	*/
				try {
					const result = await backEndApi.publicCode.removeSmallCode(params);
					this.alertNoti(removeCount + Number(result.data) + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
					this.$refs.codeDetail.resetRowSelect();
					this.reSearch();
				} catch (err) {
					new AppErrorHandler(err).setOtherMessage(popupMessages.COMMON_API_CALL_FAIL_MESSAGE, this.alertDanger).errHandling();
				}
			} else {
				this.alertNoti(removeCount + popupMessages.CODE_DELETE_SUCCESS_POPUP_MESSAGE());
				this.$refs.codeDetail.resetRowSelect();
				this.reSearch();
			}
		},

		hide() {
			this.$refs.newModal.hide();
		},
		reSearch() {
			this.$refs.search.reSearch();
		},
		resetValue() {
			this.$refs.codeDetail.selectedRow = [];
			this.selectedRow = [];
		},
	},
};
</script>

<style scoped></style>
